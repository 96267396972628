<template>
  <div class="common-bg page-container p20 bsbb">
    <van-nav-bar left-arrow @click-left="onClickLeft">
      <template #title>
        <div class="top-nav df aic jcsb">
          <div class="df aic jcc">
            <span style="font-weight: bold; font-size: 0.6rem; line-height: normal;color: #fff;">推薦人</span>
          </div>
        </div>
      </template>
    </van-nav-bar>
    <van-form @submit="onSubmit" class="mt20">
      <van-cell-group inset>
       <!-- <van-field v-model="form.mobile" label="收款账号" placeholder="收款账号"
          :rules="[{ required: true, message: '请输入收款账号' }]" /> -->
        <van-field v-model="form.invite_code" label="邀請地址" placeholder="請輸入邀請地址" :rules="[{ required: true,  }]" />
       <!-- <van-field v-model="password" type="password" maxlength="6" name="password" label="支付密码" placeholder="请输入支付密码"
          readonly clickable @click="clickField(1)" /> -->
      </van-cell-group>
      <div class="mt40">
        <van-button round block native-type="submit" style="background-color: #1a90c0;border-color: #1a90c0;">
          立即關聯
        </van-button>
      </div>
    </van-form>
    <van-number-keyboard v-model="password" :show="show1" random-key-order :maxlength="6" @blur="show1 = false" />
  </div>
</template>

<script>
import {
  getProductDetail,
  bindInviteCode,
  checkPayPwdIsCorrect,
  getScoreType,
} from '@/utils/api.js'
export default {
  data() {
    return {
      value: '',
      show1: false,
      password: '',
      title: '充值积分互转',
      form: {},
      showPicker: false,
      currencyInfo: {
        num: 0,
        money: '0.00',
        score_type: 1,
      }
    }
  },
  // created() {
  //   this.getPledgList1()
  // },
  methods: {
    clickField(type) {
      if (type === 1) {
        this.show1 = true
        this.password = ''
      }
    },
    onSubmit() {

      let { invite_code } = this.form
      let form = {
        invite_code: invite_code,

      }
        bindInviteCode(form).then((res) => {
          this.$toast(res.message)
          this.onClickLeft()
        })
    },


    onCancel() {
      this.showPicker = false
    },
    getPledgList1() {
      getPledgList().then((res) => {
        this.columns = res.data
        this.currencyInfo = {
          score_type,
          id
        }
      })
    },
    onClickLeft() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .van-badge__wrapper {
  font-size: 30px !important;
}

::v-deep .van-icon {
  font-size: 32px;
}

::v-deep .van-nav-bar__content {
  height: 70px;
  line-height: 70px;
}

.van-dropdown-menu__bar {
  box-shadow: none !important;
}

.van-nav-bar__content {
  box-shadow: none !important;
}

::v-deep .van-nav-bar__arrow {
  font-size: 46px !important;
}

.page-container {
  ::v-deep .van-field__control {
    text-align: right;
  }
}

::v-deep .van-field__label {
  margin-top: 20px;
  font-size: 40px;
  color: #000;
  width: 30%;
}

::v-deep .van-button {
  margin-top: 20px;
  font-size: 40px;
  color: #fff;
  width: 30%;
  margin: auto;
  height: 80px;
}
</style>
